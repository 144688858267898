import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Accordion, Image, Nav, Navbar } from 'react-bootstrap';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BiBox } from 'react-icons/bi';
import { BsFiles, BsGear } from 'react-icons/bs';
import { CgFileDocument } from 'react-icons/cg';
import { IoImageOutline, IoOpenOutline } from 'react-icons/io5';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import SimpleBar from 'simplebar-react';
import { useFetchStore } from '../api/account';
import Logo from '../assets/images/mini-logo.png';
import ReactHero from '../assets/images/printcart-icon-2.png';
import { Routes } from '../routes';
import Badge from './Badge';
import Button from './Button';

const CollapsableNavItem = (props) => {
  const { eventKey, title, icon, children = null, link, style, emptyChild } = props;

  return (
    <Accordion.Item
      eventKey={eventKey}
      style={style}
      className="bg-transparent border-0"
    >
      <Accordion.Button
        as={Link}
        to={link || ''}
        className="d-flex justify-content-between align-items-center bg-transparent"
        bsPrefix={emptyChild ? 'accordion-button empty-child' : ''}
      >
        <div className="d-flex align-items-center">
          <span className="me-2 text-gray-600 lh-1 fs-5">{icon}</span>
          <span className="sidebar-text">{title}</span>
        </div>
      </Accordion.Button>
      <Accordion.Body className="multi-level">
        <Nav className="flex-column">{children}</Nav>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const NavItem = (props) => {
  const {
    title,
    link,
    external,
    target,
    icon,
    image,
    badgeText,
    badgeBg = 'secondary',
    badgeColor = 'primary',
    logoNav,
    setShow,
    style,
  } = props;
  const { pathname } = useLocation();
  const classNames = badgeText
    ? 'd-flex justify-content-start align-items-center justify-content-between'
    : '';
  let navItemClassName = link === pathname ? 'active' : '';
  if (link === '/orders' && pathname === '/') {
    navItemClassName = 'active';
  }
  if (logoNav) navItemClassName += 'logo-nav';

  const linkProps = external ? { href: link } : { as: Link, to: link };

  return (
    <Nav.Item
      className={navItemClassName}
      style={style}
      onClick={() => setShow(false)}
    >
      <Nav.Link {...linkProps} target={target} className={classNames}>
        <span>
          {icon ? (
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} />{' '}
            </span>
          ) : null}
          {image ? (
            <Image
              src={image}
              width={40}
              height={40}
              className="sidebar-icon svg-icon"
            />
          ) : null}

          <span className="sidebar-text">{title}</span>
        </span>
        {badgeText ? (
          <Badge
            pill
            bg={badgeBg}
            text={badgeColor}
            className="badge-md notification-count ms-2"
          >
            {badgeText}
          </Badge>
        ) : null}
      </Nav.Link>
    </Nav.Item>
  );
};

const Sidebar = () => {
  const [show, setShow] = useState();
  const showClass = show ? 'show' : '';
  const onCollapse = () => setShow(!show);
  const { data: storeData } = useFetchStore();

  // const isShopIntegration = localStorage.getItem('pc-integration');
  const { pathname } = useLocation();
  // let defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';
  let path = pathname;
  if (pathname.indexOf('/product') === 0) path = '/products';
  if (pathname.indexOf('/project') === 0) path = '/orders';
  if (pathname.indexOf('/addSide/product') !== -1) path = '/products';

  let defaultKey = '/dashboard';

  switch (path) {
    case '/':
    case '/dashboard':
      defaultKey = '/dashboard';
      break;
    case '/order':
    case '/orders':
    case '/designs':
    case '/addProject':
      defaultKey = '/orders';
      break;
    case '/products':
    case '/addProduct':
    case '/product':
    case '/import-product':
    case '/inventory':
    case '/pod-products':
      defaultKey = '/products';
      break;
    case '/templates':
    case '/addTemplate':
    case '/template':
    case '/cliparts':
    case '/images':
    case '/fonts':
    case '/settings/appearance':
      defaultKey = '/designer';
      break;
    case '/settings':
    case '/settings/contact':
    case '/settings/security':
    case '/payment':
      defaultKey = '/settings';
      break;
    case '/connect-store':
      defaultKey = '/connect-store';
      break;

    case '/helpdesk/services':
    case '/helpdesk/tutorials':
    case '/helpdesk/workchat':
    case '/helpdesk':
    case '/helpdesk/projects':
    case '/helpdesk/projects/tickets':
    case '/helpdesk/projects/milestones':
      defaultKey = '/helpdesk';
      break;
    case '/integration':
    case '/integration/wix':
    case '/integration/shopify':
    case '/integration/wordpress':
    case '/integration/api':
      defaultKey = '/integration';
      break;
    default:
      defaultKey = '/dashboard';
      break;
  }

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-dark px-4 d-md-none"
      >
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.Dashboard.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-white border-end border-gray-200`}
        >
          <div className="sidebar-inner">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center" />
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <a
                href="https://www.printcart.com"
                className="d-flex align-items-center border-bottom border-gray-200 px-4 mb-4"
                style={{ height: 68 }}
              >
                <Image src={Logo} style={{ width: 32, height: 32 }} />
                <h5
                  className="text-dark fw-bold border-start border-gray-200 d-flex ms-2 ps-2 mb-0 align-items-center"
                  style={{ height: 32 }}
                >
                  Printcart
                </h5>
                {/* <h1 className="fs-4 ms-1 mb-0 text-gray-800">Printcart</h1> */}
              </a>
              <Accordion
                as={Nav.Item}
                defaultActiveKey={defaultKey}
                className="px-2"
              >
                <CollapsableNavItem
                  title="Dashboard"
                  eventKey="/"
                  icon={<MdOutlineSpaceDashboard />}
                  link={Routes.Dashboard.path}
                  setShow={setShow}
                  emptyChild
                />
                <CollapsableNavItem
                  title="Orders"
                  link={Routes.Orders.path}
                  icon={<BsFiles />}
                  eventKey="/orders"
                >
                  <NavItem
                    title="All Orders"
                    link={Routes.Orders.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Design Files"
                    link={Routes.Designs.path}
                    setShow={setShow}
                  />
                </CollapsableNavItem>
                <CollapsableNavItem
                  title="Products"
                  icon={<BiBox />}
                  link={Routes.Products.path}
                  eventKey="/products"
                >
                  <NavItem
                    title="Product Setting"
                    link={Routes.Products.path}
                    setShow={setShow}
                  />
                  {/* <NavItem
                    title="POD products"
                    link={Routes.PodProducts.path}
                    setShow={setShow}
                  /> */}
                  <NavItem
                    title="Side templates"
                    link={Routes.SideTemplates.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Setup Wizard"
                    link={Routes.StepWizard.path}
                    setShow={setShow}
                    target="_blank"
                    badgeText={<IoOpenOutline />}
                  />
                  {storeData && storeData.integration_type && (
                    <NavItem
                      title="Inventory Import"
                      link={Routes.Inventory.path}
                      setShow={setShow}
                    />
                  )}
                </CollapsableNavItem>
                <CollapsableNavItem
                  title="Designer"
                  icon={<IoImageOutline />}
                  eventKey="/designer"
                  link={Routes.Cliparts.path}
                >
                  <NavItem
                    title="Cliparts"
                    link={Routes.Cliparts.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Fonts"
                    link={Routes.Fonts.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Images"
                    link={Routes.Images.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Templates"
                    link={Routes.Templates.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Appearance"
                    link={Routes.SettingsAppearance.path}
                    setShow={setShow}
                  />
                </CollapsableNavItem>
                <CollapsableNavItem
                  title="Settings"
                  eventKey="/settings"
                  icon={<BsGear />}
                  link={Routes.Settings.path}
                  setShow={setShow}
                >
                  <NavItem
                    title="Account"
                    link={Routes.Settings.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Contact"
                    link={Routes.SettingsContact.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Security"
                    link={Routes.SettingsSecurity.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Payment"
                    link={Routes.Payment.path}
                    setShow={setShow}
                  />
                </CollapsableNavItem>
                <CollapsableNavItem
                  title="HelpDesk"
                  icon={<AiOutlineQuestionCircle />}
                  link={Routes.HelpDesk.path}
                  eventKey="/helpdesks"
                >
                  <NavItem
                    title="Know-How"
                    link={Routes.HelpDesk.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Premium Services"
                    link={Routes.HelpDeskService.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Project Development"
                    link={Routes.Project.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Support Ticket"
                    link={Routes.ProjectTicket.path}
                    setShow={setShow}
                  />
                  {/* <NavItem
                    title="Project Milestones"
                    link={Routes.ProjectMilestone.path}
                    setShow={setShow}
                    eventKey="projects/milestones"
                  /> */}
                </CollapsableNavItem>
                <CollapsableNavItem
                  title="Integration"
                  icon={<CgFileDocument />}
                  link={Routes.Integration.path}
                  eventKey="/integration"
                >
                  <NavItem
                    title="Integration Connect"
                    link={Routes.Integration.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Wix"
                    link={Routes.IntegrationWix.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Shopify"
                    link={Routes.IntegrationShopify.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Wordpress"
                    link={Routes.IntegrationWordpress.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="API"
                    link={Routes.IntegrationApi.path}
                    setShow={setShow}
                  />
                </CollapsableNavItem>
              </Accordion>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
